@import "~@bfl/components/theming/constants";
@import "~@bfl/components/theming/variables";
@import "~@bfl/components/theming/utils";

.graph-tooltip {
  @include bfc-font-type($regular-font);
  width: 160px;
  height: 320px;
  white-space: normal;
  padding-left: 0.5 * $bfc-default-spacing;

  .headline {
    @include bfc-font-size($info-font-size);
    padding-bottom: $bfc-default-spacing;
  }

  .serie {
    @include bfc-font-size($info-font-size);
  }

  .value-unit {
    @include bfc-font-size($info-font-size);
    padding-bottom: $bfc-default-spacing;
  }
}

mat-expansion-panel.bfc-expansion-panel.compare-expansion-panel {
  .mat-expansion-panel-content {
    .mat-expansion-panel-body {
      padding: 0 0.85em;
    }
  }
}


// Fix for time input fields
.bfc-form-field .mat-input-element[type=time]::after {
  display: none;
}